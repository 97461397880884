.reoccupation {
  min-height: 70vh;
  padding-top: 20px;



  .reoccupation-table {
    background-color: #F8F9FC;
    min-height: 600px;
    padding: 40px 20px 40px 20px;

    .reoccupation-table-title {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;
      .title {
        font-size: 20px;
        display: flex;
        justify-content: center;
        gap: 5px;

        .bold {
          font-weight: 800;
        }        
      }

      .export {
        display: flex;
        justify-content: end;
        margin-right: 50px;
      }
      margin-bottom: 20px;
    }

    .cell-libelle {
      font-weight: 500;
    }
  }

  .missing-data {
    position: relative;
    max-width: 600px;
    top: -70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    opacity: 0;
    animation: fadeIn 0.5s ease 0.5s forwards;
  }

  .no-data-table {
    position: absolute;
    top: 140%;
  }
}

.line {
  margin-top: 140px;
  margin-bottom: 60px;

  .title {
    margin-bottom: 20px;
  }
}

.reoccupation-graph {

  .select-container {
    margin-top: 20px;
    margin-right: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .select {
      width: 250px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 50vh;
}
