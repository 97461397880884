.choice-module {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 40px;

  .select {
    display: flex;
    flex-direction: column;
    gap: 10px;
    

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      vertical-align: middle;
      border: 1px solid #D0D8F4;
      border-radius: 8px;
      padding: 25px;
      background-color: var(--color-white);
      color: #000000;
      font-weight: 500;
      font-size: 16px;

      .label {
        display: flex;
        align-items: center;
        gap: 20px;
        .item {
          font-size: 12px;
          font-style: italic;
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: var(--color-lighter-grey);

        &:hover {
          border: 1px solid #D0D8F4;
        }
      }

      &:hover {
        border: 1px solid var(--color-medium-blue-100);
      }

      svg {
        width: 16px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
